import React, { useState } from 'react';
import './App.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import '/node_modules/primeflex/primeflex.css'
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import Keycloak from 'keycloak-js';

let initOptions = {
	url:		'https://id.travels.pw/',
	realm:		'third-party-provider',
	clientId:	'www-travels-pw',
	responseType:	'code'
}

let kc = new Keycloak(initOptions);

kc.init({
	
	onLoad: 			'check-sso',		// check-sso | login-required
	silentCheckSsoRedirectUri:	window.location.origin + "/silent-check-sso.html",
	checkLoginIframe:		false,			// iframe invisible pour surveiller si user tj connecté
	responseType: 			'code',
	responseMode: 			'fragment',
	pkceMethod: 			'S256',
	enableLogging: 			true			// disable for PROD
	
}).then((auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    console.info("Authenticated");
    console.log('auth', auth)
    console.log('Keycloak', kc)
    kc.onTokenExpired = () => {
      console.log('token expired')
    }
  }
}, () => {
  console.error("Authenticated Failed");
  //console.log(kc);
});

const prettyPrintJson = (jsonObject) => {
	return jsonObject ? JSON.stringify(jsonObject, null, '\t').replace(/\n/g, '<br/>').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;') : null;
};

function App() {

  const [infoMessage, setInfoMessage] = useState('');

  return (
    <div className="App">
	
	<Button onClick={() => { window.location.href = 'https://gami.augment3d.dev' }}  className="m-1" label='Earn points' />

	
      <div className='grid'>
        <div className='col-12 tit'>
          <h1>TRAVELS CORP</h1>
        </div>
      </div>
      <div className="grid">
        <div className="col">
		
        <Button onClick={() => { setInfoMessage(kc.authenticated ? 'Authenticated: TRUE' : 'Authenticated: FALSE') }} className="m-1" label='Is Authenticated' />
         
          <Button onClick={() => { kc.login() }} className='m-1' label='Login' severity="success" />
		  
          <Button onClick={() => { setInfoMessage(kc.token) }} className="m-1" label='Show Access Token' severity="info" />

          <Button onClick={() => { setInfoMessage(   prettyPrintJson(kc.tokenParsed)  ) }} className="m-1" label='Pretty Print Access token' severity="info" />
		  
          <Button onClick={() => { setInfoMessage(kc.isTokenExpired(5).toString()) }} className="m-1" label='Check Token expired' severity="warning" />
		  
          <Button onClick={() => { kc.updateToken(10).then((refreshed)=>{ setInfoMessage('Token Refreshed: ' + refreshed.toString()) }, (e)=>{setInfoMessage('Refresh Error')}) }} className="m-1" label='Update Token (if about to expire)' />  {/** 10 seconds */}
          
		  <Button onClick={() => { kc.logout({ redirectUri: window.location.href }) }} className="m-1" label='Logout' severity="danger" />
          
        </div>
      </div>

	{infoMessage && (
      <div className='grid'>
        <div className='col-2'/>
        <div className='col-8'>
          <Card>
			<p style={{ wordBreak: 'break-all' }} id='infoPanel' 
				dangerouslySetInnerHTML={{ __html: infoMessage }}>
			</p>
          </Card>
        </div>
        <div className='col-2'/>
      </div>
	  )}
	  
    </div>
	
	
	
  );
}


export default App;
